import React from "react"
import { styled } from "baseui"
import { Theme } from "baseui/theme"
import Common from "./Common"
import Scenes from "./Scenes"

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  background: $theme.colors.white,
}))

const Graphic = () => {
  return (
    <Container className="bottom-bar ">
      <div className="bottom-bar-inner flex w-full items-center">
        <div
          style={{
            background: "white",
          }}
          className="mx-2  p-1 rounded-xl mb-2 "
        >
          <Common />
        </div>
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            width: "100%",
          }}
          className="w-full p-1"
        >
          <Scenes />
        </div>
      </div>
    </Container>
  )
}

export default Graphic
