import { useStyletron, styled } from "baseui"
import { BASE_ITEMS } from "~/constants/app-options"
import useAppContext from "~/hooks/useAppContext"
import Icons from "~/components/Icons"
import { useTranslation } from "react-i18next"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"

const Container = styled("div", (props) => ({
  width: "80px",

  display: "flex",
}))

const PanelsList = () => {
  const { activePanel } = useAppContext()
  const { t } = useTranslation("editor")
  const PANEL_ITEMS = BASE_ITEMS
  return (
    <Container
      style={{
        border: "2px solid #e7daf3",
      }}
      className="left-panel-container mt-3  sp-container "
    >
      <Scrollable autoHide={true}>
        {PANEL_ITEMS.map((panelListItem) => (
          <PanelListItem
            label={t(`panels.panelsList.${panelListItem.id}`)}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        ))}
      </Scrollable>
    </Container>
  )
}

const PanelListItem = ({ label, icon, activePanel, name }: any) => {
  const { setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()
  icon = icon.split(" ").join("")

  console.log("icon", icon)

  // @ts-ignore
  const Icon = Icons[icon]
  return (
    <Block
      id="EditorPanelList"
      onClick={() => {
        setIsSidebarOpen(true)
        setActivePanel(name)
      }}
      className={`item ${activePanel === name ? "active" : ""}`}
    >
      <Icon size={24} />
      {label == "Canvas Background" ? (
        <div className="">
          <div className="flex justify-center items-center ">Canvas Background</div>{" "}
        </div>
      ) : label == "Element Themes" ? (
        <div className="">
          <div className="flex justify-center items-center ">Element Themes</div>
        </div>
      ) : label == "Letters And Numbers" ? (
        <div className="">
          <div className="flex justify-center items-center ">Letters & Numbers</div>
        </div>
      ) : label == "9,250 Elements" ? (
        <div className="">
          <div className="flex justify-center items-center ">9,250</div>
          <div className="flex justify-center items-center">Elements</div>
        </div>
      ) : label == "Upload Elements" ? (
        <div className="">
          <div className="flex justify-center items-center ">Upload Elements</div>
          {/* <div className="flex justify-center items-center">Elements</div> */}
        </div>
      ) : (
        <div className="flex justify-center items-center flex-nowrap">{label}</div>
      )}
    </Block>
  )
}

export default PanelsList
