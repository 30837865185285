import Background from "./Background"
import Elements from "./Elements"
import Text from "./Text"
import Templates from "./Templates"
import Search from "./Search"
import Images from "./Images"
import Graphics from "./Graphics"
import Pixabay from "./Pixabay"
import Uploads from "./Uploads"
import Logo from "./Logo"
import Compress from "./Compress"
import Expand from "./Expand"
import Undo from "./Undo"
import Redo from "./Redo"
import Layers from "./Layers"
import Refresh from "./Refresh"
import TimePast from "./TimePast"
import AddCircleOutline from "./AddCircleOutline"
import RemoveCircleOutline from "./RemoveCircleOutline"
import InformationCircleOutline from "./InformationCircleOutline"
import PageSizes from "./PageSizes"
import SwapHorizontal from "./SwapHorizontal"
import ArrowBackOutline from "./ArrowBackOutline"
import Underline from "./Underline"
import Add from "./Add"
import Video from "./Video"
import Categories from "./Categories"
import FontSelector from "./FontSelector"
import Draw from "./Draw"
import Eraser from "./Eraser"
import ClearAll from "./ClearAll"
import Backgrounds from "./Theme"
import CanvasBackground from "./CanvasBackground"
import LettersIcon from "./Letters"

class Icons {
  static Background = Background
  static Elements = Elements
  static Text = Text
  static Templates = Templates
  static Search = Search
  static Images = Images
  static Videos = Video
  static Graphics = Graphics
  static Pixabay = Pixabay
  static Uploads = Uploads
  static Logo = Logo
  static Compress = Compress
  static Expand = Expand
  static Eraser = Eraser
  static Undo = Undo
  static Redo = Redo
  static Refresh = Refresh
  static Layers = Layers
  static TimePast = TimePast
  static AddCircleOutline = AddCircleOutline
  static RemoveCircleOutline = RemoveCircleOutline
  static InformationCircleOutline = InformationCircleOutline
  static PageSizes = PageSizes
  static SwapHorizontal = SwapHorizontal
  static ArrowBackOutline = ArrowBackOutline
  static Underline = Underline
  static Add = Add
  static Categories = Text
  static FontSelector = FontSelector
  static Draw = Draw
  static ClearAll = ClearAll
  static Letters = Search
  static ElementThemes = Backgrounds
  static CanvasBackground = CanvasBackground
}

export default Icons
