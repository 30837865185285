import React, { useState } from "react"
import { useEditor, useFrame } from "@layerhub-io/react"
import { Modal, ROLE } from "baseui/modal"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { sampleFrames } from "~/constants/editor"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import toPX from "to-px"
import { useAppDispatch } from "~/store/store"
import { setSize } from "~/store/slices/current-frame-size/actions"
import { ChevronLeftCircleIcon } from "lucide-react"
import { Input } from "~/components/ui/input"
import { Button } from "~/components/ui/button"

interface State {
  backgroundColor: string
}

const PageSizes = () => {
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()

  const [state, setState] = React.useState<State>({
    backgroundColor: "#000000",
  })

  const changeBackgroundColor = (color: string) => {
    if (editor) {
      editor.frame.setBackgroundColor(color)
    }
  }
  const handleChange = (type: string, value: any) => {
    setState({ ...state, [type]: value })
    changeBackgroundColor(value)
  }

  return (
    <Block className="panel" $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem",
        }}
      >
        <Block className="title">Adjust Design Sizes</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <ChevronLeftCircleIcon size={18} />
        </Block>
      </Block>
      <Scrollable>
        <Block padding="0 1.5rem">
          <Block>
            <ResizeTemplate />
          </Block>

          <Block paddingTop="0.5rem"></Block>
        </Block>
      </Scrollable>
    </Block>
  )
}

const ResizeTemplate = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState<string | number>("0")
  const { currentDesign, setCurrentDesign } = useDesignEditorContext()
  const [customWidth, setCustomWidth] = useState("")
  const [customHeight, setCustomHeight] = useState("")
  const [customFormat, setCustomFormat] = useState("in")
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const [desiredFrame, setDesiredFrame] = React.useState({
    width: 0,
    height: 0,
  })
  const [selectedFrame, setSelectedFrame] = React.useState<any>(sampleFrames[0])
  const frame = useFrame()

  React.useEffect(() => {
    if (frame) {
      setDesiredFrame({
        width: toPX(`${frame.width}in`) || 1200,
        height: toPX(`${frame.height}in`) || 1200,
      })
    }
  }, [frame])

  const applyResize = () => {
    dispatch(
      setSize({
        currentSize: {
          id: selectedFrame.id,
          height: selectedFrame.height,
          width: selectedFrame.width,
        },
      })
    )

    // @ts-ignore
    const size = activeKey === "0" ? selectedFrame : desiredFrame
    if (editor) {
      editor.frame.resize({
        width: toPX(`${size.width}in`) || 1200,
        height: toPX(`${size.height}in`) || 1200,
      })
      setCurrentDesign({
        ...currentDesign,
        frame: {
          width: toPX(`${size.width}in`) || 1200,
          height: toPX(`${size.height}in`) || 1200,
        },
      })
    }
    setIsOpen(false)
  }
  const isEnabled =
    // @ts-ignore
    (activeKey === "0" && selectedFrame.id !== 0) ||
    // @ts-ignore
    (activeKey === "1" && !!parseInt(desiredFrame.width) && !!parseInt(desiredFrame.height))

  function handleCustomResize() {
    if (parseInt(customWidth) <= 0 || parseInt(customHeight) <= 0) return alert("Please enter a valid number")
    let customResizeObj = {
      height:
        customFormat == "in"
          ? parseFloat(customHeight)
          : customFormat == "cm"
          ? parseFloat(customHeight) * 0.393701
          : parseInt(customHeight) / 300,
      width:
        customFormat == "in"
          ? parseFloat(customWidth)
          : customFormat == "cm"
          ? parseFloat(customWidth) * 0.393701
          : parseInt(customWidth) / 300,
      id: 0,
      name: "Custom",
      isRecommend: false,
      unit: "in",
    }
    setSelectedFrame(customResizeObj)
    dispatch(
      setSize({
        currentSize: {
          id: customResizeObj.id,
          height: customResizeObj.height,
          width: customResizeObj.width,
        },
      })
    )

    // @ts-ignore
    const size = activeKey === "0" ? customResizeObj : desiredFrame
    if (editor) {
      editor.frame.resize({
        width: toPX(`${size.width}in`) || 1200,
        height: toPX(`${size.height}in`) || 1200,
      })
      setCurrentDesign({
        ...currentDesign,
        frame: {
          width: toPX(`${size.width}in`) || 1200,
          height: toPX(`${size.height}in`) || 1200,
        },
      })
    }
    setIsOpen(false)
  }

  return (
    <>
      <h1 className="mb-2 font-semibold text-sm">Custom Size</h1>
      <Block>
        <Block $style={{}}>
          <Block
            $style={{
              height: "85px",
              display: "flex",
              alignItems: "center",
              marginBottom: "25px",
              // marginTop: "10px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Block className="flex" $style={{ fontSize: "13px", textAlign: "center" }}>
              <Input
                type="text"
                placeholder={`W-(${customFormat})`}
                value={customWidth}
                style={{
                  fontSize: "12px",
                }}
                className="   rounded-md h-8  text-sm  mr-1 px-2 py-3"
                onChange={(e) => {
                  setCustomWidth(e.target.value)
                }}
              />
              <Input
                type="text"
                placeholder={`H-(${customFormat})`}
                value={customHeight}
                style={{
                  fontSize: "12px",
                }}
                className="w-24 font-mono  rounded-md h-8 mr-0 px-2 py-3"
                onChange={(e) => setCustomHeight(e.target.value)}
              />
              <select
                className=" w-14 font-mono ml-1 border  rounded-md h-8 px-2"
                onChange={(e) => {
                  setCustomFormat(e.target.value)
                }}
              >
                <option value="in">In</option>
                <option value="cm">Cm</option>
                <option value="px">Px</option>
              </select>
            </Block>
            <Block $style={{ fontSize: "13px", marginTop: "10px" }}>
              <Button onClick={() => handleCustomResize()} className=" ">
                Resize
              </Button>
            </Block>
          </Block>
        </Block>
      </Block>

      <h1 className="mb-2 font-semibold mt-4">Recommended</h1>
      <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr " }}>
        {sampleFrames.map(
          (sampleFrame, index) =>
            sampleFrame.isRecommended && (
              <Block
                onClick={() => {
                  setSelectedFrame(sampleFrame)

                  setIsOpen(true)
                }}
                $style={{
                  padding: "0.5rem",
                  borderRadius: "10px",
                  backgroundColor: selectedFrame.id === sampleFrame.id ? "#eee" : "#ffffff",
                  ":hover": {
                    backgroundColor: "rgb(246,247,248)",
                    cursor: "pointer",
                  },
                }}
                key={index}
              >
                <Block
                  $style={{
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={sampleFrame.preview} className="object-scale-down h-20" />
                </Block>
                <Block $style={{ fontSize: "11px", textAlign: "center", fontFamily: "Montserrat" }}>
                  <Block $style={{ fontWeight: 500 }}>{sampleFrame.name}</Block>
                  <Block $style={{ color: "rgb(119,119,119)", fontFamily: "Montserrat" }}>
                    {sampleFrame.width} x {sampleFrame.height} in
                  </Block>
                </Block>
              </Block>
            )
        )}
      </Block>

      {/* <h1 className="my-5 font-bold">More sizes</h1> */}

      <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        {sampleFrames.map(
          (sampleFrame, index) =>
            !sampleFrame.isRecommended && (
              <Block
                onClick={() => {
                  setSelectedFrame(sampleFrame)

                  setIsOpen(true)
                }}
                $style={{
                  padding: "0.5rem",
                  backgroundColor: selectedFrame.id === sampleFrame.id ? "rgb(243,244,245)" : "#ffffff",
                  ":hover": {
                    backgroundColor: "rgb(246,247,248)",
                    cursor: "pointer",
                  },
                }}
                key={index}
              >
                <Block
                  $style={{
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={sampleFrame.preview} className="object-scale-down h-20" />
                </Block>
                <Block $style={{ fontSize: "13px", textAlign: "center" }}>
                  <Block $style={{ fontWeight: 500 }}>{sampleFrame.name}</Block>
                  <Block $style={{ color: "rgb(119,119,119)" }}>
                    {sampleFrame.width} x {sampleFrame.height} in
                  </Block>
                </Block>
              </Block>
            )
        )}
      </Block>
      {/* </Scrollbar> */}
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={true}
        isOpen={isOpen}
        animate
        autoFocus
        size="auto"
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <Block $style={{ padding: "0 1.5rem", width: "640px" }}>
          <Block
            $style={{
              padding: "2rem 1rem 1rem",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            Are you sure you want to resize the page?
          </Block>
        </Block>
        <Block $style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "2rem" }}>
          <Button
            disabled={!isEnabled}
            onClick={() => {
              setIsOpen(false)
            }}
            style={{ width: "190px", marginRight: "1rem" }}
          >
            Cancel
          </Button>
          <Button disabled={!isEnabled} onClick={applyResize} style={{ width: "190px" }}>
            Resize template
          </Button>
        </Block>
      </Modal>
    </>
  )
}

export default PageSizes
