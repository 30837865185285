import { useEffect, useState } from "react"
import ComBG from "../../components/common/Bg"
import ComButton from "../../components/common/Button1"
import ComInput from "../../components/common/Input"
import { ILoginResponse, login } from "~/services/auth"
import Brandlogo from "../../../public/assets/logos/brand_logo_purple.png"
// import OneTapComponent from "~/components/Auth/oneTapComponent"
import { Input } from "~/components/ui/input"
import { Label } from "~/components/ui/label"
import { RotateCcw, RotateCw } from "lucide-react"
import { Button } from "~/components/ui/button"

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<any>("")
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  async function loginUser() {
    setLoading(true)
    setError(false)

    if (loading) {
      return false
    }
    try {
      let result: ILoginResponse = await login({ username: email, password })
      // // console.log(result.data.error.full)
      if (result.token) {
        sessionStorage.setItem("token", result.token)
        window.location.href = "/"
      } else {
        setShowError(true)
        if (result && result.Error) {
          setError(result.Error)
        } else {
          setError("Something went wrong")
        }
      }
    } catch (e: any) {
      console.error(e)
      setError(e.message)
    }
    setLoading(false)
  }

  const [alert, setAlert] = useState({ show: false, message: "" })

  return (
    <div className="w-full">
      {/* <OneTapComponent /> */}
      <div
        style={{
          minHeight: "80vh",
        }}
        className=" my-10 flex items-center  w-fit min-h-[60vh]  px-10  mx-auto"
      >
        <>
          <div
            style={{
              borderRadius: "20px",
              padding: "20px 30px",
            }}
            className="flex  font-jsans justify-center bg-[#f7f7f8] py-[20px] h-fit w-full items-center h-[100vh]"
          >
            <div
              className="g-recaptcha"
              data-sitekey="6Lc1o_chAAAAAGcjhYsF3wbyebemeS0CiBQkV87w"
              data-size="invisible"
            ></div>

            <div className="flex  justify-center mx-auto items-center flex-col ">
              <div>
                {alert.show && <div className="w-full my-8">{alert.message}</div>}
                <div className="">
                  <h4
                    className="mx-auto font-extrabold text-center"
                    style={{
                      marginTop: "15px",
                      fontSize: "34px",
                      marginBottom: "15px",
                    }}
                  >
                    Log In To Self Publishing Titans
                  </h4>

                  <div
                    style={{
                      width: "400px",
                    }}
                    className="mt-[20px] text-center mx-auto"
                  >
                    To stay connected with us, please log in using your personal details.
                  </div>
                </div>

                <form
                  style={{
                    marginBottom: "15px",
                    marginTop: "35px",
                  }}
                  className="w-full flex flex-col "
                  action=""
                >
                  <div className="flex flex-col gap-2">
                    <Label
                      htmlFor="email"
                      className="font-bold"
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      Enter Email Address
                    </Label>
                    <input
                      required
                      id="email"
                      style={{
                        width: "500px",
                      }}
                      name="email"
                      className="pInput h-[50px] text-[15px] px-3  bg-white"
                      placeholder="Email Address"
                      autoComplete="email"
                      onChange={(e: any) => setEmail(e.target.value)}
                      autoFocus
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "25px",
                    }}
                    className="flex flex-col gap-2"
                  >
                    <Label
                      htmlFor="password"
                      className="font-bold"
                      style={{
                        marginLeft: "18px",
                      }}
                    >
                      Enter Password
                    </Label>
                    <input
                      required
                      id="password"
                      style={{
                        width: "500px",
                      }}
                      name="password"
                      type="password"
                      className="pInput h-[50px] text-[15px] px-3  bg-white"
                      placeholder="Password"
                      onChange={(e: any) => setPassword(e.target.value)}
                      autoFocus
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {/* <ReCAPTCHA size="invisible" sitekey="6Lc1o_chAAAAAGcjhYsF3wbyebemeS0CiBQkV87w" ref={recaptchaRef} /> */}
                  </div>

                  <div className="flex text-[14px] mt-[25px] justify-between mt-2 px-4">
                    <div>
                      <a href="/auth/forgot" className="">
                        Forgot password?
                      </a>
                    </div>
                  </div>

                  <Button
                    style={{
                      backgroundColor: "#7249fb",
                      color: "white",
                    }}
                    disabled={loading}
                    className="pButton mt-2 bg-[#7249fb] font-bold"
                    onClick={(e) => loginUser()}
                  >
                    {!loading ? "Sign In" : <RotateCw className="animate-spin" />}
                  </Button>
                </form>
              </div>

              <div className="mt-[30px] flex justify-center"></div>
              <div>
                <a href="/register" className="relative text-[14px] font-bold cursor-pointer">
                  {"Don't have an account? Sign Up"}
                </a>
              </div>
              {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            </div>
          </div>
        </>
      </div>
      );
    </div>
  )
}

export default Login
