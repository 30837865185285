import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox"
import { HexColorPicker } from "react-colorful"
import React from "react"
import { Delete } from "baseui/icon"
import { throttle } from "lodash"
import { useEditor } from "@layerhub-io/react"
import { useEffect, useState } from "react"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { useDispatch } from "react-redux"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import { ChevronLeftCircle } from "lucide-react"
const PRESET_COLORS = [
  "#f44336",
  "#ff9800",
  "#ffee58",
  "#66bb6a",
  "#26a69a",
  "#03a9f4",
  "#3f51b5",
  "#673ab7",
  "#9c27b0",
  "#ec407a",
  "#8d6e63",
  "#d9d9d9",
]

const CanvasBackground = () => {
  const editor = useEditor()
  const [applyToAllPages, setApplyToAllPages] = useState(false)
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  let scenes = useDesignEditorPages()
  const [color, setColor] = useState("")
  const setIsSidebarOpen = useSetIsSidebarOpen()

  useEffect(() => {
    if (currentScene) {
      //@ts-ignore
      setChecked(currentScene?.layers[0].fill === "transparent" ? true : false)
      //@ts-ignore
      setColor(currentScene?.layers[0].fill)
    }
  }, [currentScene])

  //Sets transparant background
  function setTransparantBackground() {
    editor.frame.setBackgroundColor("transparent")
  }

  //to handle transparant background toggle
  function handleSelectChange(val: any) {
    setChecked(val)
    if (val) {
      setTransparantBackground()
    } else {
      editor.frame.setBackgroundColor(color)
    }
  }

  //updates all scenes background
  const updateBulkCanvasBackgrounds = throttle(async (newColor: string) => {
    setColor(newColor)
    if (checked) return
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Replacing Element's Background...",
      })
    )
    let modifiedScenes: any = []
    await Promise.all(
      scenes.map(async (scene: any) => {
        let layers = scene.layers
        let modifiedLayers: any = [...layers]
        modifiedLayers[0].fill = newColor
        modifiedScenes.push({ ...scene, layers: modifiedLayers })
      })
    )
    let finalScenes: any = await Promise.all(
      modifiedScenes.map(async (scene: any) => {
        let preview = await editor.renderer.render(scene)
        return { ...scene, preview }
      })
    )

    finalScenes.sort((a: any, b: any) => a.ind - b.ind)
    let newScenes = finalScenes.map((obj: any) => {
      const { ind, ...newObj } = obj
      return newObj
    })

    setScenes(newScenes)
    setCurrentScene(finalScenes[finalScenes.length - 1])
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }, 100)

  //updates current scene background
  const updateCanvasBackground = throttle((color: string) => {
    setColor(color)
    if (checked) return alert("Please Uncheck the Transparant Background Option")
    editor.frame.setBackgroundColor(color)
  }, 100)

  //to decide single or bulk update
  const handleCanvasBgChange = throttle((newColor: string) => {
    if (checked) return alert("Please Uncheck the Transparant Background Option to Apply Color")
    if (applyToAllPages) {
      updateBulkCanvasBackgrounds(newColor)
    } else {
      updateCanvasBackground(newColor)
    }
  }, 100)

  return (
    <Block className="panel" $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem",
        }}
      >
        <Block className=" title">Canvas Background</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <ChevronLeftCircle size={18} />
        </Block>
      </Block>
      <Scrollable>
        <Block padding="0 1.5rem" className="mt-5 font-normal">
          <Checkbox
            checked={checked}
            onChange={(e) => handleSelectChange(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <span className="font-normal text-[16px]">Transparant Background</span>
          </Checkbox>
          <Block $style={{ marginTop: "20px" }}>
            <Checkbox
              checked={applyToAllPages}
              onChange={(e) => setApplyToAllPages(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <span className="font-normal text-[16px]">Apply To All Pages</span>
            </Checkbox>
          </Block>
          <HexColorPicker
            onChange={handleCanvasBgChange}
            style={{ width: "100%", cursor: `${checked ? `not-allowed` : ""}` }}
            className="mt-8 rounded-md"
          />

          <Block>
            <Block
              $style={{ padding: "0.75rem 0", fontWeight: 700, fontSize: "16px" }}
              className="font-primary font-semibold text-lg mt-10"
            >
              Preset colors
            </Block>
            <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "0.25rem" }}>
              {PRESET_COLORS.map((presetColor, index) => (
                <Block
                  $style={{
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                  onClick={() => handleCanvasBgChange(presetColor)}
                  backgroundColor={presetColor}
                  height="30px"
                  // className="rounded-sm"
                  key={index}
                />
              ))}
            </Block>
          </Block>
        </Block>
      </Scrollable>
    </Block>
  )
}

export default CanvasBackground
